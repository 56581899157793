import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-ventura-california.png'
import image0 from "../../images/cities/scale-model-of-public-art-\"sundancer\"-in-ventura-california.png"
import image1 from "../../images/cities/scale-model-of-the-robert-j.-lagomarsino-visitor-center-at-channel-islands-national-park-in-ventura-california.png"
import image2 from "../../images/cities/scale-model-of-mission-san-buenaventura-in-ventura-california.png"
import image3 from "../../images/cities/scale-model-of-ventura-visitors-and-convention-bureau-in-ventura-california.png"
import image4 from "../../images/cities/scale-model-of-visit-oxnard-in-ventura-california.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Ventura'
            state='California'
            image={image}
            lat='34.2804923'
            lon='-119.29451990000001'
            attractions={ [{"name": "Public Art \"Sundancer\"", "vicinity": "S Victoria Ave, Oxnard", "types": ["point_of_interest", "establishment"], "lat": 34.1987522, "lng": -119.22081120000001}, {"name": "The Robert J. Lagomarsino Visitor Center at Channel Islands National Park", "vicinity": "1901 Spinnaker Dr, Ventura", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 34.2484504, "lng": -119.26655349999999}, {"name": "Mission San Buenaventura", "vicinity": "211 E Main St, Ventura", "types": ["museum", "church", "place_of_worship", "point_of_interest", "establishment"], "lat": 34.2811033, "lng": -119.29819179999998}, {"name": "Ventura Visitors and Convention Bureau", "vicinity": "101 S California St, Ventura", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 34.2794271, "lng": -119.29327769999998}, {"name": "Visit Oxnard", "vicinity": "2775 N Ventura Rd #204, Oxnard", "types": ["travel_agency", "point_of_interest", "local_government_office", "establishment"], "lat": 34.2439108, "lng": -119.18400650000001}] }
            attractionImages={ {"Public Art \"Sundancer\"":image0,"The Robert J. Lagomarsino Visitor Center at Channel Islands National Park":image1,"Mission San Buenaventura":image2,"Ventura Visitors and Convention Bureau":image3,"Visit Oxnard":image4,} }
       />);
  }
}